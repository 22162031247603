import {Link} from "react-router-dom";
import {useMachine} from "@xstate/react"
import styles from "./OwnerRegister.module.scss";
// import {useCallback, useEffect, useState} from "react"
import { Table } from '@/js/modules/Table/Table'
import { ownerDistColumns } from '@/js/constants'
import { distOwnersMachine } from "@/js/xstate/owners.machine";
import {Pagination} from "@/js/modules/Pagination/Pagination";


export function OwnerRegister() {
  const [stateDistOwners, sendDistOwners] = useMachine(distOwnersMachine);

  const tableData = stateDistOwners.context.list.map((item) => ({
    ...item,
    sale_points: <ul>
      {item.sale_points.map((item, idx) => (
        <li key={idx}>{item}</li>
      ))}
    </ul>
  }))
  const {meta} = stateDistOwners.context

  return (
    <section className={styles.container}>
      <div className="wrapper">
        <div className={styles.header}>
          <h2>
            Руководители<br/>Торговых Точек
          </h2>
          <Link to="/account/owner-add" className="btn btnPrimary btnIcon">
              Зарегистрировать Руководителя ТТ
          </Link>
        </div>
        <Table 
          overflow="auto"
          headers={ownerDistColumns}
          tableData={tableData}
          isLoading={stateDistOwners.matches("loading")}
        />
        {
          stateDistOwners.context.list.length && meta.lastPage > 1 && !stateDistOwners.matches("loading") ?
          <Pagination 
            pages={stateDistOwners.context.pages}
            current_page={stateDistOwners.context.current_page}
            send={sendDistOwners}
          /> : <></>
        }
      </div>
    </section>
  )
}
import React, { useEffect, useState } from 'react'
import { FormTemplate } from "@/js/modules/CreationPages/FormTemplate/FormTemplate";
import apiMethods from "@/js/api/apiMethods";
import { useMachine } from "@xstate/react";
import { salePointEditMachine } from '@/js/xstate/salePointEdit';
import { useParams } from "react-router-dom"
import { SuccessPopup } from "@/js/modules/SuccessPopup/SuccessPopup";

export function SalePointEditForm() {
  const [stateSalePoint, sendSalePoint] = useMachine(salePointEditMachine)
  const { id } = useParams()

  const [isSuccessPopup, setSuccessPopup] = useState()

  const handleSubmit = (event) => {
    event.preventDefault();

    sendSalePoint({
      type: "SUBMIT_POINT"
    })
  }

  useEffect(() => {
    sendSalePoint({
      type: "SET_POINT_ID",
      data: id
    })

    sendSalePoint({
      type: "GET_POINT"
    })
  }, [])

  useEffect(() => {
    if (stateSalePoint.context.message) {
      setSuccessPopup(true);
    }
  }, [stateSalePoint.context.message]);

  return (
    <FormTemplate title='Редактировать' isLoading={false}>
      {
        <form onSubmit={handleSubmit}
        >
          <div className="formRow">
              <label htmlFor="name">
                  Название
              </label>
              <input
                  id="name"
                  type="text"
                  name="name"
                  disabled
                  value={stateSalePoint.context.point.name}
              />
              {/* {
                  stateFeedback.context.errorMessage.map(item=> {
                      return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                          {item.emailError}
                      </div>
                  })
              } */}
          </div>
          <div className="formRow">
              <label htmlFor="entity_name">
                  Полное/Юр название
              </label>
              <input
                  id="entity_name"
                  type="text"
                  name="entity_name"
                  disabled
                  value={stateSalePoint.context.point.entity_name}
              />
              {/* {
                  stateFeedback.context.errorMessage.map(item=> {
                      return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                          {item.emailError}
                      </div>
                  })
              } */}
          </div>
          <div className="formRow">
              <label htmlFor="region">
                  Регион
              </label>
              <input
                  id="region"
                  type="text"
                  name="region"
                  disabled
                  value={stateSalePoint.context.point.region}
              />
              {/* {
                  stateFeedback.context.errorMessage.map(item=> {
                      return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                          {item.emailError}
                      </div>
                  })
              } */}
          </div>
          <div className="formRow">
              <label htmlFor="address">
                  Адрес
              </label>
              <input
                id="address"
                type="text"
                name="address"
                disabled
                value={stateSalePoint.context.point.region}
              />
              {/* {
                  stateFeedback.context.errorMessage.map(item=> {
                      return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                          {item.emailError}
                      </div>
                  })
              } */}
          </div>
          <div className="formRow">
            <label htmlFor="owner">
            Руководитель
            </label>
            <input
                id="owner"
                type="text"
                name="owner"
                disabled
                value={stateSalePoint.context.point.owner}
            />
              {/* {
                  stateFeedback.context.errorMessage.map(item=> {
                      return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                          {item.emailError}
                      </div>
                  })
              } */}
          </div>
          <div className="formRow">
            <label htmlFor="status">
                Статус
            </label>
            <select
              className="select"
              id="status"
              type="text"
              name="status"
              value={stateSalePoint.point?.status?.slug}
              onChange={(event) => {
                sendSalePoint({
                  type: "INPUT_STATUS",
                  key: 'status',
                  data: event.target.value
                })
              }}
            >
              <option value="active">активна</option>
              <option value="inactive">не активна</option>
            </select>
              {
                stateSalePoint.context.errorMessage.map(item=> {
                  return item.statusError !== "" && <div className={"errorMessage"} key={"errStatus"}>
                    {item.statusError}
                  </div>
                })
              }
          </div>
          <div className="formRow">
            <button type="submit" className={"btn btnPrimary"}>
              Сохранить
            </button>
          </div>
        </form>
      }
      <SuccessPopup
        isOpen={isSuccessPopup}
        onClose={() => setSuccessPopup(false)}
        title={stateSalePoint.context.message}
      />
    </FormTemplate>
  )
}
import React, { useState, useCallback, useEffect } from "react";
import { useMachine, useActor } from "@xstate/react";
// import styles from "../SalePointApplicationForm.module.scss";
import { FormTemplate } from "@/js/modules/CreationPages/FormTemplate/FormTemplate";
import { salePointMachine } from "@/js/xstate/salePoint.machine";
import apiMethods from "@/js/api/apiMethods";
import { AuthState } from "@/js/xstate/auth.machine";
import { SuccessPopup } from "@/js/modules/SuccessPopup/SuccessPopup";

const title = "Заявка на регистрацию Торговой точки";

export function SalePointApplicationForm() {
  const [stateSalePoint, sendSalePoint] = useMachine(salePointMachine);
  const [authState] = useActor(AuthState);
  const [regions, setRegions] = useState([])
  const [ownersList, setOwnersList] = useState([]);
  const [isSuccessPopup, setSuccessPopup] = useState(
    Boolean(stateSalePoint.context.message),
  );
  const handleSubmit = (event) => {
    event.preventDefault();

    sendSalePoint({
      type:
        authState.context.type === "owner"
          ? "SUBMIT_OWNER"
          : "SUBMIT_DISTRIBUTOR",
    });
  };

  const getRegionsList = useCallback(async () => {
    try {
      const response = await apiMethods.salePoints.getRegions();

      if (response.status === 200) {
        const options = response.data.data.map(region => ({
          label: region.name,
          value: region.name
        }))

        setRegions(options)
      }
    } catch (err) {
      console.log(err?.response?.message)
    }
  }, [])

  const getOwnersList = useCallback(async () => {
    if (authState.context.type !== "owner") {
      const response = await apiMethods.owners.getData({
        limit: 9999,
      });

      if (response.status === 200) {
        const options = response.data.data.map((item) => ({
          label: `${item.name} ${item.surname}`,
          value: item.id,
        }));

        setOwnersList(options);
      }
    }
  }, []);

  useEffect(() => {
    document.title = `${title} | Программа лояльности SK ZIC`;
    
    getOwnersList();
    getRegionsList()
  }, []);

  useEffect(() => {
    setSuccessPopup(Boolean(stateSalePoint.context.message));
  }, [stateSalePoint.context.message]);

  return (
    <FormTemplate
      title="Заявка на регистрацию Торговой точки"
      isLoading={false}
    >
      {stateSalePoint.context.errorMessage.map(
        (item) =>
          item.serverError &&
          item.serverError !== "" && (
            <div className="errorMessage" key="errName">
              {item.serverError}
            </div>
          ),
      )}

      <form onSubmit={handleSubmit}>
        <div className="formRow">
          <label htmlFor="name">Название {stateSalePoint.context.name}</label>
          <input
            id="name"
            type="text"
            name="name"
            value={stateSalePoint.context.name}
            onChange={(event) => {
              sendSalePoint({
                type: "INPUT_NAME",
                key: "name",
                data: event.target.value,
              });
            }}
          />
          {stateSalePoint.context.errorMessage.map(
            (item) =>
              item.nameError &&
              item.nameError !== "" && (
                <div className="errorMessage" key="errName">
                  {item.nameError}
                </div>
              ),
          )}
        </div>
        <div className="formRow">
          <label htmlFor="entity_name">Полное/ юридическое название</label>
          <input
            id="entity_name"
            type="text"
            name="entity_name"
            value={stateSalePoint.context.entity_name}
            onChange={(event) => {
              sendSalePoint({
                type: "INPUT_ENTITY_NAME",
                key: "entity_name",
                data: event.target.value,
              });
            }}
          />
          {stateSalePoint.context.errorMessage.map(
            (item) =>
              item.entity_nameError &&
              item.entity_nameError !== "" && (
                <div className="errorMessage" key="errEntityName">
                  {item.entity_nameError}
                </div>
              ),
          )}
        </div>
        <div className="formRow">
          <label htmlFor="region">Регион</label>
          <select
              id="region"
              name="region"
              value={stateSalePoint.context.region}
              onChange={(event) => {
                sendSalePoint({
                  type: "INPUT_REGION",
                  key: "region",
                  data: event.target.value,
                });
              }}
              className="select"
            >
              <option value="" selected disabled>
                Выберите
              </option>
              {regions.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          {stateSalePoint.context.errorMessage.map(
            (item) =>
              item.regionError &&
              item.regionError !== "" && (
                <div className="errorMessage" key="errRegion">
                  {item.regionError}
                </div>
              ),
          )}
        </div>
        <div className="formRow">
          <label htmlFor="address">Адрес</label>
          <input
            id="address"
            type="text"
            name="address"
            value={stateSalePoint.context.address}
            placeholder="Город, улица, дом"
            onChange={(event) => {
              sendSalePoint({
                type: "INPUT_ADDRESS",
                key: "address",
                data: event.target.value,
              });
            }}
          />
          {stateSalePoint.context.errorMessage.map(
            (item) =>
              item.addressError &&
              item.addressError !== "" && (
                <div className="errorMessage" key="errAddress">
                  {item.addressError}
                </div>
              ),
          )}
        </div>
        {authState.context.type !== "owner" ? (
          <div className="formRow">
            <label htmlFor="name">Руководитель</label>
            <select
              id="owner"
              name="owner"
              value={stateSalePoint.context.owner}
              onChange={(event) => {
                sendSalePoint({
                  type: "INPUT_OWNER",
                  key: "owner",
                  data: +event.target.value,
                });
              }}
              className="select"
            >
              <option value="" selected disabled>
                Выберите
              </option>
              {ownersList.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {stateSalePoint.context.errorMessage.map(
              (item) =>
                item.ownerError &&
                item.ownerError !== "" && (
                  <div className="errorMessage" key="errOwner">
                    {item.ownerError}
                  </div>
                ),
            )}
          </div>
        ) : (
          <></>
        )}
        <div className="formRow">
          <button type="submit" className="btn btnPrimary">
            Зарегистрировать
          </button>
        </div>
      </form>
      <SuccessPopup
        isOpen={isSuccessPopup}
        onClose={() => setSuccessPopup(false)}
        title="Заявка на регистрацию Торговой точки успешно отправлена"
        text={stateSalePoint.context.message}
      />
    </FormTemplate>
  );
}

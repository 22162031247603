import { useActor } from "@xstate/react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDialogState, DialogDisclosure } from "reakit";
import styles from "./AuthForm.module.scss";
import { AuthState } from "../../xstate/auth.machine";
import { Icon } from "../SVGsprite/svgsprite";
import { Loader } from "../Loader/Loader";
import {
  validateEmail,
  validatePassword,
} from "../../helpers/validateAuthFormFields";
import { RegisterInfoModal } from "../RegisterInfoModal/RegisterInfoModal";

export function AuthForm() {
  const [stateAuth, sendAuth] = useActor(AuthState);

  const [passShown, setPassShown] = useState(false);

  const dialog = useDialogState({ animated: true });

  const navigate = useNavigate();
  useEffect(() => {
    if (AuthState.state.matches("auth")) {
      navigate("/account");
    }
  });

  const [errorLogin, setErrorLogin] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [loginState, setLoginState] = useState("");
  const [passState, setPassState] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    const FD = new FormData(event.target);
    const login = FD.get("email");
    const password = FD.get("password");

    const passwordError = validatePassword(password);
    const loginError = validateEmail(login);

    if (!loginError && !passwordError && privacyPolicy) {
      sendAuth({
        type: "login",
        data: {
          email: login,
          password: password,
        },
      });
    }

    if (loginError) {
      setErrorLogin(loginError);
    }
    if (passwordError) {
      setErrorPass(passwordError);
    }
  };

  return (
    <>
      {!stateAuth.matches("success") ||
        (!stateAuth.matches("unAuth") && (
          <section className={styles.loader}>
            <Loader />
          </section>
        ))}

      <section className="formWrapper">
        <div className={clsx("wrapperSecondary", styles.wrapper)}>
          <h1 className="textCenter">Войти</h1>
          <p className="textCenter">
            Приветствуем Вас на сайте мотивационной платформы ZIC.
          </p>

          <p className="textCenter">
            Для получения логина и пароля к системе обратитесь к Вашему
            дистрибьютору.
          </p>

          {stateAuth.context.errorMsg ? (
            <div className="errorMessage">{stateAuth.context.errorMsg}</div>
          ) : (
            <></>
          )}
          <form onSubmit={handleSubmit} noValidate>
            <div className="formRow">
              <label htmlFor="email">E-mail</label>
              <input
                className={clsx({ error: Boolean(errorLogin) })}
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                onChange={(event) => {
                  setLoginState(event.target.value);
                  setErrorLogin("");
                }}
                value={loginState}
              />

              {Boolean(errorLogin) && (
                <div className="errorMessage">{errorLogin}</div>
              )}
            </div>
            <div className="formRow passwordRow">
              <label htmlFor="password">Пароль</label>
              <div className="inputWrapper">
                <input
                  className={clsx({ error: errorPass })}
                  id="password"
                  name="password"
                  autoComplete="password"
                  type={passShown ? "text" : "password"}
                  minLength="8"
                  onChange={(event) => {
                    setPassState(event.target.value);
                    setErrorPass("");
                  }}
                  value={passState}
                />

                <button
                  className="passToggle"
                  type="button"
                  onClick={() => setPassShown(!passShown)}
                >
                  <Icon
                    name={passShown ? "icon-eye" : "icon-eyeClosed"}
                    sizeW="24"
                    sizeH="24"
                    sizeWV="32"
                    sizeHV="32"
                  />
                  <span className="visuallyHidden">
                    {passShown ? "Скрыть" : "Показать"} пароль
                  </span>
                </button>
              </div>
              {Boolean(errorPass) && (
                <div className="errorMessage">{errorPass}</div>
              )}
            </div>
            <div className={styles.linksRow}>
              <Link className="extraLink" to="forgot">
                Забыли пароль?
              </Link>
              <DialogDisclosure {...dialog} className="extraLink">
                Как зарегистрироваться?
                <span className="visuallyHidden">Как зарегистрироваться?</span>
              </DialogDisclosure>
            </div>
            <div className={styles['field-group']}>
              <label className={styles['field-label']}>
                <input
                  className={styles.checkbox}
                  id="privacy_policy"
                  name="privacy_policy"
                  type="checkbox"
                  checked={privacyPolicy}
                  onChange={(event) => setPrivacyPolicy(event.target.checked)}
                />
                <span>
                  Авторизовываясь на сайте вы даете согласие на обработку <a href="./docs/policy.pdf" target="__blank">персональных данных</a>
                </span>
              </label>
            </div>
            <div className="formRow">
              <button type="submit" className="btn btnPrimary">
                Войти
              </button>
            </div>
          </form>
        </div>
        <RegisterInfoModal dialog={dialog} />
      </section>
    </>
  );
}
